import { TFunction } from 'i18next';
import { SelectOption } from 'types/common';
import { UserMemberRelation } from '../types/authentication';

export const BASE_URL = process.env.REACT_APP_BACKEND_URL || '';
export const PATIENT_URL = '/patient';
export const SIGNUP_SUCCESS = `${PATIENT_URL}/sign-up-success`;

export const DEPARTMENT_LEAD = 'cbbe6169326242d58737d109c8c9e235';
export const SUPERADMIN = '64f79287a4ec4c85bb854e8cfd44f013';
export const RECEPTIONIST = '592363571a7749f397b79d12598a2513';
export const THIRD_PARTY = 'b6f969ce23034f4abd0444cef4dc09df';
export const SPECIALIST = 'eea53d1b0b664bf99ff892d662aa8feb';
export const SPECIALIST_PLUS = 'b9b1d11325d84c4293899a3b9d738e13';
export const GP_SURGERY = 'cbcee4f4ae724cc2b1bd517401167f6e';
export const PATIENT = 'ca4114e30186407399110612650356be';

export const ROLE_OFFSITE_RECEPTIONIST = 'e86241da72fa4b2382b8dd0140989c57';
export const ROLE_SPECIALIST_PLUS_WITH_REPORTS =
  'ae4e4202d8484969a8a4c33b67573236';

export type UserRoleId =
  | typeof SUPERADMIN
  | typeof DEPARTMENT_LEAD //Department
  | typeof RECEPTIONIST
  | typeof THIRD_PARTY
  | typeof SPECIALIST
  | typeof SPECIALIST_PLUS
  | typeof GP_SURGERY
  | typeof PATIENT
  | typeof ROLE_OFFSITE_RECEPTIONIST
  | typeof ROLE_SPECIALIST_PLUS_WITH_REPORTS;

export const USER_ROLE_IDS_ORDERED: UserRoleId[] = [
  SUPERADMIN,
  DEPARTMENT_LEAD,
  ROLE_SPECIALIST_PLUS_WITH_REPORTS,
  SPECIALIST_PLUS,
  SPECIALIST,
  RECEPTIONIST,
  ROLE_OFFSITE_RECEPTIONIST,
  THIRD_PARTY,
  GP_SURGERY,
  PATIENT,
];

export const ALL_ROLES_BUT_SPECIALIST: UserRoleId[] =
  USER_ROLE_IDS_ORDERED.filter((role) => role !== SPECIALIST);

export const ALL_ROLES_BUT_PATIENT: UserRoleId[] = USER_ROLE_IDS_ORDERED.filter(
  (role) => role !== PATIENT,
);

export const ALL_STAFF_ROLES: UserRoleId[] = USER_ROLE_IDS_ORDERED.filter(
  (role) => role !== PATIENT && role !== SUPERADMIN,
);

export const ALL_STAFF_ROLES_BUT_RECEPTIONIST_AND_THIRD_PARTY_AND_SURGERY: UserRoleId[] =
  USER_ROLE_IDS_ORDERED.filter(
    (role) =>
      role !== GP_SURGERY &&
      role !== RECEPTIONIST &&
      role !== THIRD_PARTY &&
      role !== PATIENT,
  );

export const ALL_STAFF_ROLES_BUT_SPECIALIST_AND_THIRD_PARTY_AND_SURGERY: UserRoleId[] =
  USER_ROLE_IDS_ORDERED.filter(
    (role) =>
      role !== GP_SURGERY &&
      role !== SPECIALIST &&
      role !== THIRD_PARTY &&
      role !== PATIENT,
  );

// Equivalent of GET_PATIENT_RELEASENOTE permission
export const GET_PATIENT_RELEASENOTE_ROLES: UserRoleId[] = [
  SUPERADMIN,
  PATIENT,
];
// Equivalent of GET_STAFF_RELEASENOTE permission
export const GET_STAFF_RELEASENOTE_ROLES: UserRoleId[] = [
  SUPERADMIN,
  DEPARTMENT_LEAD,
  RECEPTIONIST,
  THIRD_PARTY,
  SPECIALIST_PLUS,
  SPECIALIST,
  GP_SURGERY,
];
// Equivalent of GET_EMAIL_TEMPLATE permission
export const GET_EMAIL_TEMPLATE_ROLES: UserRoleId[] = [
  SUPERADMIN,
  DEPARTMENT_LEAD,
];

const staffAccess = {
  main_route: '/staff',
  have_access_to: ['/staff'],
};

export const USER_ROLES_PATHS: {
  [key in UserRoleId]: { main_route: string; have_access_to: string[] };
} = {
  [SUPERADMIN]: {
    main_route: '/admin',
    have_access_to: ['/admin', '/staff'],
  },
  [DEPARTMENT_LEAD]: staffAccess,
  [RECEPTIONIST]: staffAccess,
  [ROLE_OFFSITE_RECEPTIONIST]: staffAccess,
  [THIRD_PARTY]: staffAccess,
  [SPECIALIST]: staffAccess,
  [SPECIALIST_PLUS]: staffAccess,
  [ROLE_SPECIALIST_PLUS_WITH_REPORTS]: staffAccess,
  [GP_SURGERY]: staffAccess,
  [PATIENT]: {
    main_route: PATIENT_URL,
    have_access_to: [PATIENT_URL, SIGNUP_SUCCESS],
  },
};

export const disabilityOptions: (t: TFunction) => SelectOption<number>[] = (
  t,
) => [
  {
    label: t('disability-movement'),
    value: 1,
  },
  {
    label: t('disability-hearing'),
    value: 2,
  },
  {
    label: t('disability-sight'),
    value: 4,
  },
];

export const RELATIONSHIPS: (t: TFunction) => {
  [key in UserMemberRelation]: string;
} = (t) => ({
  0: t('mainMember'),
  1: t('child'),
  2: t('spouse'),
  3: t('parent'),
  4: t('sibling'),
  5: t('other'),
});

export const RELATIONSHIP: (t: TFunction) => SelectOption<number>[] = (t) => [
  {
    label: t('spouse'),
    value: 2,
  },
  {
    label: t('parent'),
    value: 3,
  },
  {
    label: t('child'),
    value: 1,
  },
  {
    label: t('sibling'),
    value: 4,
  },
  {
    label: t('other'),
    value: 5,
  },
];

export const TABLE_ID_SEPARATOR = '___SEPARATOR___';
export const ATTRIBUTE_SEPARATOR = '___ATTRITBUTE___';
export const CHECKBOX_ATTRIBUTE_SEPARATOR = '___CHECKBOX_ATTRITBUTE___';

export const APPT_DURATIONS: SelectOption<number>[] = [
  {
    label: '5 Min',
    value: 5,
  },
  {
    label: '10 Min',
    value: 10,
  },
  {
    label: '15 Min',
    value: 15,
  },
  {
    label: '20 Min',
    value: 20,
  },
  {
    label: '30 Min',
    value: 30,
  },
];

export const colorSchemeValueWithBackgroundGraphics = '--greentercial';

export const colorSchemes: (
  t: TFunction,
) => { label: string; value: string }[] = (t) => [
  {
    label: t('green'),
    value: colorSchemeValueWithBackgroundGraphics,
  },
  {
    label: t('teal'),
    value: '--tealtercial',
  },
  {
    label: t('blue'),
    value: '--bluemtercial',
  },
];

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const GA_LOGIN_EVENT_INTERVAL_MINUTES = process.env
  .REACT_APP_GA_LOGIN_EVENT_INTERVAL_MINUTES
  ? parseInt(process.env.REACT_APP_GA_LOGIN_EVENT_INTERVAL_MINUTES)
  : 90;
export const CLARITY_PROJECT_ID = process.env.REACT_APP_CLARITY_PROJECT_ID;
